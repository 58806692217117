import React from "react";
import tw from "twin.macro";

import LogoImage from "../../images/regen-logo.png";
import {ReactComponent as SvgDecoratorBlob1} from "../../images/svg-decorator-blob-9.svg";
import SubscribeForm from "../forms/SubscribeForm";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as TiktokIcon } from "images/tiktok.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedIn.svg";

import styled from "styled-components";

const Container = tw.div`relative text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24 bg-secondary-400`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap sm:text-left justify-between sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-2 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold text-gray-100`;

const LinkList = tw.ul`mt-6 text-sm font-medium text-gray-100`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-400 pb-1 transition duration-300`;

const SubscribeNewsletterColumn = tw(
  Column,
)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-100`;
const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;
const HighlightedText = tw.span`text-gray-100`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-full`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-100`;
const FooterNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-100 w-4/5 mx-auto`;
const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1,
)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-100 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1,
)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-100 opacity-50`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <Content>
        <FooterNotice>
          Prof Paul Lee is an Honorary Professor, College of Health and Science, University of Lincoln and Consultant Orthopedic Surgeon at MSK Doctors, founder of MAI Motion artificial intelligent platform, onMRI medical imaging analysis and founder of Regen PhD.
        </FooterNotice>
        <Divider />
        <SixColumns>
          <Column>
            <ColumnHeading>Main</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/about-us">About Us</Link>
              </LinkListItem>
{/*              <LinkListItem>
                <Link href="/services">Services</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/super-human">Super human</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/approach">Approach</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/blogs">Blogs</Link>
              </LinkListItem>*/}
              <LinkListItem>
                <Link href="/contact-us">Contact Us</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Product</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="https://app.maimotion.com">Regeneration Man</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="https://mskdoctors.com/cookie">Cookie Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://mskdoctors.com/privacy">
                  Privacy Policy
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://mskdoctors.com/tos">Terms of Service</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Office</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link
                  href="https://www.google.com/maps/search/?api=1&query=MSK+Doctors+The+Keep+Clinic+The+Old+Barracks+Sandon+Road+Grantham+NG31+9AS+United+Kingdom"
                  target="_blank"
                >
                  Regeneration Man,
                  <br />
                  The Keep Clinic, The Old Barracks,
                  <br />
                  Sandon Road, Grantham,
                  <br />
                  NG31 9AS, United Kingdom
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:support@regenman.com">
                  <HighlightedText>Email: </HighlightedText>{" "}
                  live@regenman.com
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="tel:+4403300010048">
                  <HighlightedText>Phone: </HighlightedText> +4403300010048
                </Link>
              </LinkListItem>
            </LinkList>
          </Column>

          <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
              <SubscribeText>
                We deliver high quality blog posts written by professionals
                weekly. And we promise no spam.
              </SubscribeText>
              <SubscribeForm />
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg alt="Regenman" src={LogoImage} />
          </LogoContainer>
          <CopywrightNotice>
            &copy; 2024 Regeneration Man. All Rights Reserved.
          </CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink href="https://tiktok.com/@regenerationman" target="_blank">
              <TiktokIcon />
            </SocialLink>
            <SocialLink href="https://x.com/@regenbydesign" target="_blank">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com/@regenerationman" target="_blank">
              <YoutubeIcon />
            </SocialLink>
            <SocialLink href="http://linkedin.com/in/paulleephd" target="_blank">
              <LinkedInIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
};
